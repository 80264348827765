<template>
  <div>
    <el-form :inline="true" :model="form" ref="ruleForm" label-width="100px">
      <el-form-item class="form-item" label="用户ID" prop="user_id">
        <el-input v-model="form.user_id" placeholder="请输入用户ID"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="用户手机" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="问卷验收状态" prop="paper_status">
        <el-select v-model="form.paper_status" placeholder="全部">
          <el-option label="待验收" value="1"></el-option>
          <el-option label="通过" value="2"></el-option>
          <el-option label="未通过" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="完课状态"
        prop="finish_class_status"
      >
        <el-select v-model="form.finish_class_status" placeholder="全部">
          <el-option label="未完课" value="1"></el-option>
          <el-option label="已完课" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="返现状态" prop="tk_status">
        <el-select v-model="form.tk_status" placeholder="全部">
          <el-option label="待返现" value="1"></el-option>
          <el-option label="返现成功" value="2"></el-option>
          <el-option label="返现失败" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-content">
      <div class="table-title">完课返现活动用户列表</div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column fixed prop="order_number" label="订单号" width="180">
        </el-table-column>
        <el-table-column
          prop="type"
          label="课程类型"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="goods" label="课程名称" width="200">
        </el-table-column>
        <el-table-column
          prop="level_id"
          label="所属级别"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="theme" label="主题" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="user_id"
          label="用户ID"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="username"
          label="用户昵称"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="paidtime"
          label="支付时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="订单状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="finish_class_status"
          label="完课状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="paper_code"
          label="问卷码"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="paper_status"
          label="问卷验收状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="approver_name"
          label="验收者"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tk_audit_status"
          label="审核状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tk_status"
          label="返现状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template #default="scope">
            <el-button
              @click="handleClick(scope.$index)"
              type="text"
              size="small"
              >问卷验收</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
    </div>
    <el-dialog title="问卷验收" v-model="dialogFormVisible" width="500px">
      <el-form :model="ruleForm" label-width="100px">
        <el-form-item label="问卷验收：">
          <el-radio-group v-model="ruleForm.paper_status">
            <el-radio label="2">通过</el-radio>
            <el-radio label="3">未通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注信息：">
          <el-input
            type="textarea"
            v-model="ruleForm.remarks"
            :maxlength="200"
            :rows="6"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handCancel">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="问卷验收" v-model="passVisible" width="500px">
      <el-form label-width="100px">
        <el-form-item label="问卷验收：">
          <div>通过</div>
        </el-form-item>
        <el-form-item label="备注信息：">
          <div>{{ ruleForm.remarks }}</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="passVisible = false"
            >关 闭</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import { geWktList, checkPaper } from "@/api/apiList/operate-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        user_id: "",
        phone: "",
        paper_status: "",
        finish_class_status: "",
        tk_status: "",
      },
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 10,
      loading: true,
      dialogFormVisible: false,
      ruleForm: {
        paper_status: "",
        remarks: "",
        id: "",
      },
      passVisible: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.handList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClick(e) {
      this.ruleForm.id = this.tableData[e].id;
      this.ruleForm.remarks = this.tableData[e].remarks;
      if (this.tableData[e].paper_status === "通过") {
        this.ruleForm.paper_status = "2";
        this.passVisible = true;
      } else {
        if (this.tableData[e].paper_status === "待验收") {
          this.ruleForm.paper_status = "1";
        } else {
          this.ruleForm.paper_status = "3";
        }
        this.dialogFormVisible = true;
      }
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    handCancel() {
      this.dialogFormVisible = false;
      this.ruleForm = {
        paper_status: "",
        remarks: "",
        id: "",
      };
    },
    submitForm() {
      this.$confirm("请仔细核对，验收通过后不可二次更改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          checkPaper(this.ruleForm).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.data.info,
              });
              this.handList();
              this.dialogFormVisible = false;
            } else if (res.result === "300015") {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已关闭验收",
          });
        });
    },
    handList() {
      geWktList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.type) {
              case 1:
                item.type = "系统课(主课)";
                break;
              case 2:
                item.type = "单词课(小课包)";
                break;
            }
            switch (item.level_id) {
              case 1:
                item.level_id = "U-1(L1)";
                break;
              case 2:
                item.level_id = "U-2(L4)";
                break;
            }
            switch (item.status) {
              case 0:
                item.status = "待支付";
                break;
              case 1:
                item.status = "已支付";
                break;
              case 2:
                item.status = "已发货";
                break;
            }
            switch (item.finish_class_status) {
              case 1:
                item.finish_class_status = "未完课";
                break;
              case 2:
                item.finish_class_status = "已完课";
                break;
            }
            switch (item.paper_status) {
              case 1:
                item.paper_status = "待验收";
                break;
              case 2:
                item.paper_status = "通过";
                break;
              case 3:
                item.paper_status = "未通过";
                break;
            }
            switch (item.tk_audit_status) {
              case 1:
                item.tk_audit_status = "待审核";
                break;
              case 2:
                item.tk_audit_status = "审核通过";
                break;
              case 3:
                item.tk_audit_status = "审核不通过";
                break;
            }
            switch (item.tk_status) {
              case 1:
                item.tk_status = "待返现";
                break;
              case 2:
                item.tk_status = "返现成功";
                break;
              case 3:
                item.tk_status = "返现失败";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.handList();
  },
};
</script>

<style></style>
